

import { Vue, Component } from 'vue-property-decorator'
import { Warehousing } from '@/types/material.d'

@Component({ name: 'WarehousingDetail' })
export default class extends Vue {
  formData: Warehousing = {
    projectId: '',
    storageDate: '',
    storageExplain: '',
    detailedList: []
  }

  created () {
    this.loadData()
  }

  get id () {
    return this.$route.query.id as string
  }

  loadData () {
    this.$axios.get(this.$apis.material.selectStorageById, {
      id: this.id
    }).then(res => {
      this.formData = res
    })
  }
}
